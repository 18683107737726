<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row >
      <b-col
        cols="12"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <!-- Transaction No Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.code')"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    size="sm"
                    v-model="form.code"
                    placeholder="CI-2021020001"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="companyActive.plan_id > 2">
                <b-form-group :label="$t('globalSingular.office')" label-for="officeId">
                  <validation-provider
                    #default="{ errors }"
                    name="officeId"
                  >
                    <v-select
                      id="officeId"
                      v-model="form.officeId"
                      :options="LOV.offices"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Date Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.date')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      :config="dateFormat"
                      v-model="form.date"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Tag Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.tags')"
                  label-for="tags"
                >
                  <v-select
                    v-model="form.tags"
                    id="tags"
                    label="name"
                    multiple
                    :options="LOV.tags"
                    :reduce="field => field.id"
                    :searchable="!actions.isPreview"
                    :selectable="option => !actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <!-- Description Field -->
              <b-col cols="12" md="8">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.notes')"
                  label-for="notes"
                >
                  <b-form-textarea
                    id="notes"
                    v-model="form.remark"
                    rows="1"
                    :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>


            <b-table
              id="refFormTable"
              ref="refFormTable"
              fixed
              no-provider-paging
              no-provider-filtering
              :fields="tableColumns"
              :items="form.details"
              primary-key="id"
              show-empty
              class="position-relative mt-2"
            >
              <!-- Custom Header Column -->
              <template #head(actions)>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="mx-auto"
                />
              </template>
              <!-- Column: Account -->
              <template #cell(accountId)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="account"
                    rules="required"
                    :vid="`detail-${index}`"
                  >
                    <v-select
                      id="account-type"
                      v-model="form.details[index].accountId"
                      :options="LOV.accounts"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                    :vid="`detail-${index}`"
                  >
                    <b-form-input
                      v-model="form.details[index].remark"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: Debit Amount -->
              <template #cell(debit)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    :rules="!form.details[index].creditAmount ? 'required' : ''"
                    :vid="`detail-${index}`"
                  >
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].debitAmount"
                      :state="errors.length > 0 ? false:null"
                      class="form-control text-right"
                      :options="numeric"
                      :disabled="form.details[index].creditAmount || actions.isPreview"
                      :placeholder="(form.details[index].creditAmount || actions.isPreview) ? null : '100,000'"
                      @blur="sumAmount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: Credit Amount -->
              <template #cell(credit)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    :rules="!form.details[index].debitAmount ? 'required' : ''"
                    :vid="`detail-${index}`"
                  >
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].creditAmount"
                      :state="errors.length > 0 ? false:null"
                      class="form-control text-right"
                      :options="numeric"
                      :disabled="form.details[index].debitAmount || actions.isPreview"
                      :placeholder="(form.details[index].debitAmount || actions.isPreview) ? null : '100,000'"
                      @blur="sumAmount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="{ index }">
                <b-button
                  variant="flat-danger"
                  class="btn-icon"
                  :disabled="form.details.length <= 2"
                  @click="removeRow(index)"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </template>
              <!-- Costum Footer -->
              <template v-slot:custom-foot="{}">
                <b-tr>
                  <b-td :class="['pl-0', 'd-flex', 'align-items-baseline', actions.isPreview ? 'justify-content-end': 'justify-content-between']">
                    <b-button
                      v-if="!actions.isPreview"
                      variant="flat-primary"
                      @click="addRow"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                      {{ $t('globalActions.addMore') }}
                    </b-button>
                    <div class="h4 mb-0 font-weight-bolder text-right">{{ $t('globalSingular.total') }}</div>
                  </b-td>
                  <b-td class="text-right align-baseline">
                    <div class="h4 mb-0 font-weight-bolder">{{ totalDebit }}</div>
                    <small class="text-danger">{{ notBalance }}</small>
                  </b-td>
                  <b-td class="text-right align-baseline">
                    <div class="h4 mb-0 font-weight-bolder">{{ totalCredit }}</div>
                    <small class="text-danger">{{ notBalance }}</small>
                  </b-td>
                  <b-td>&nbsp;</b-td>
                </b-tr>
              </template>
            </b-table>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-transactions-journal-list' }"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref, onMounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatCurrency } from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave
  },
  setup () {
    const { 
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/journal', localeContextPath: 'apps.transactionsJournal.singular.transaction', redirectPathName: 'apps-transactions-journal' })

    const { $t } = useLocalization()

    const LOV = ref({
      accounts: [],
      tags: [],
      offices: []
    })

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts/child'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    // Get offices
    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }

    const tableColumns = ref([])
    const notBalance = ref('')

    const form = ref({
      code: '',
      date: '',
      remark: '',
      tags: [],
      officeId: null,
      debitAmount: 0,
      creditAmount: 0,
      details: [
        // doing 'tricked' placeholder for vue-select accountId
        { accountId: `${$t('apps.transactionsJournal.singular.account')}`, remark: '', debitAmount: null, creditAmount: null },
        { accountId: `${$t('apps.transactionsJournal.singular.account')}`, remark: '', debitAmount: null, creditAmount: null }
      ]
    })

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y', 
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    const totalDebit = ref(0)
    const totalCredit = ref(0)
    const sumAmount = () => {
      form.value.debitAmount = form.value.details.reduce((accumulator, {debitAmount}) => accumulator + debitAmount, 0)
      form.value.creditAmount = form.value.details.reduce((accumulator, {creditAmount}) => accumulator + creditAmount, 0)
      totalDebit.value = formatCurrency(form.value.debitAmount)
      totalCredit.value = formatCurrency(form.value.creditAmount)
      notBalance.value = (totalDebit.value !== totalCredit.value) ? $t('validations.fields.notBalance') : null
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data.code
      form.value.officeId = data.office_id
      form.value.date = data.date
      form.value.remark = data.remark
      form.value.tags = data.tags.map(tag => parseInt(tag))
      form.value.debitAmount = data.debit_amount
      form.value.creditAmount = data.credit_amount
      form.value.details = data.journal_detail
      form.value.details = form.value.details.map(field => {
        const detail = {}
        detail.accountId = field.account_id
        detail.remark = field.remark
        detail.debitAmount = field.amount > 0 ? field.amount : null
        detail.creditAmount = field.amount < 0 ? field.amount *  -1 : null
        return detail
      })
      totalDebit.value = formatCurrency(form.value.debitAmount)
      totalCredit.value = formatCurrency(form.value.creditAmount)
    }

    // Add row details
    const addRow = () => {
      form.value.details.push({ accountId: `${$t('apps.transactionsJournal.singular.account')}`, remark: '', debitAmount: null, creditAmount: null })
    }

    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
      sumAmount()
    }

    onMounted(() => {
      getAccounts()
      getTags()
      if (companyActive.plan_id > 2) {
        getOffice()
      }
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      tableColumns.value = [
        { key: 'accountId', label: $t('apps.transactionsJournal.singular.account'), width: 'auto', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        // { key: 'remark', label: $t('apps.transactionsCashbank.singular.description'), width: 'auto', thClass: 'bg-transparent', tdClass: 'align-top'},
        { key: 'debit', label: $t('globalSingular.debit'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'credit', label: $t('globalSingular.credit'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    return {
      companyActive,
      notBalance,
      pushTags,
      required,
      LOV,
      tableColumns,
      form,
      addRow,
      removeRow,
      dateFormat,
      totalDebit,
      totalCredit,
      sumAmount,
      numeric,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    async handleSubmit () {
      // const validated = this.$refs.formRef.value.validate()
      // if (validated) {
      //   if (this.totalDebit !== this.totalCredit) {
      //     this.notBalance = 'Not Balance'
      //   }
      // }
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
